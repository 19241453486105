import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/device/reducers";
import SlidingImage1 from "../../../Images/Sliding-Image-1.jpg";
import SlidingImage2 from "../../../Images/Sliding-Image-2.jpg";
import SlidingImage3 from "../../../Images/Sliding-Image-3.jpg";
import SlidingImage4 from "../../../Images/Sliding-Image-4.jpg";
import SlidingImage5 from "../../../Images/Sliding-Image-5.jpg";
import SlidingImage6 from "../../../Images/Sliding-Image-6.jpg";
import SlidingImage7 from "../../../Images/Sliding-Image-7.jpg";
import "../forgot/forgot.scss";
import useWindowSize from "../../../redux/device/useWindowSize";
import Popup from "../../../ui-elements/popup/popup";
import { useCheck_RegMutation } from "../../../generated/graphql";

const VerifyPage: React.FC = () => {
  useWindowSize();
  const { device } = useSelector((state: RootState) => state.windowSize);
  const [email, setEmail] = useState<string>("");
  const { id1 = "", id2 = "" } = useParams<{ id1?: string; id2?: string }>();
  const navigate = useNavigate(); // Initialize navigate hook

  const Images = [
    SlidingImage1,
    SlidingImage2,
    SlidingImage3,
    SlidingImage4,
    SlidingImage5,
    SlidingImage6,
    SlidingImage7,
  ];
  
  const [currentIndex, setCurrentIndex] = useState<number>(
    Math.floor(Math.random() * 7)
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % Images.length);
    }, 3000); // Change image every 3 seconds
    return () => clearInterval(interval);
  }, []);

  const [checkReg, { data:vData, loading, error }] = useCheck_RegMutation();

  useEffect(() => {
    async function verifyEmail() {
      try {
        const response = await checkReg({
          variables: {
            loginHash: id2,
            emailBase64: id1,
          },
        });
      } catch (e) {
      }
    }

    verifyEmail();
  }, [id1, id2, checkReg]);

  // Redirect to home after 5 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/"); // Redirect to home page
    }, 3000); // 5000 milliseconds = 5 seconds

    return () => clearTimeout(timer); // Cleanup timer on component unmount
  }, [navigate]);

  return (
    <div className="signup_box">
      {error && <Popup message={error.message.split(":")[1]} type={"error"} />}
      {loading && <Popup message="loading.." type={"loading"} />}
      {vData && <Popup message="Mail verified " type={"success"} />}

      <div className={`registration-form-and-image-forgot ${device}`}>
        <div className={`sliding-image-forgot login ${device}`}>
          {Images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Slide ${index}`}
              className={`image-slider__image-forgot ${
                index === currentIndex ? "active" : ""
              }`}
            />
          ))}
        </div>

        <div className={`registration-form active ${device}`}>
          <div className={`forgot-box ${device}`}>
            <div className={`heading-forgot text-white ${device} `}>
              Verification Successful!
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyPage;
