import React from 'react';
import './Banner.scss'; // Importing CSS file
import useWindowSize from '../../redux/device/useWindowSize';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/device/reducers';

interface ScrollingBannerProps {
  place?: string;
}

const ScrollingBanner: React.FC<ScrollingBannerProps> = ({ place = '' }) => {
    useWindowSize();
  const { device } = useSelector((state: RootState) => state.windowSize);
  return (
    <div className={`banner-container ${device} ${place}`}>
      <div className="scrolling-text">
      Buy 5 or more Combos together & get a flat 5% discount on your purchase!!
      </div>
    </div>
  );
};

export default ScrollingBanner;
