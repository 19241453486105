import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setWindowSize } from './actions';

const useWindowSize = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      dispatch(setWindowSize(width, height));
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call initially to set the state with the initial size

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [dispatch]);
};

export default useWindowSize;
