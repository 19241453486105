import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./Navbar.css";
import { RxCross1 } from "react-icons/rx";
import { RootState } from "../../redux/device/reducers";
import { useSelector } from "react-redux";
// import logo from "../../Images/logo.png";
import axiosInstance from "../../graphql/axiosConfig";
import NavbarContext from "../contexts/NavbarContext";
import zIndex from "@mui/material/styles/zIndex";
import Logo from '../../assets/saarang_logo.png'
const Navbar = () => {
  const navigate = useNavigate();
  const { activeItem, setActiveTab } = useContext(NavbarContext);
  const location = useLocation();
  const menuItems = ["Home", "Tickets", "Combos", "Merch", "Cart","Orders"];
  const { device } = useSelector((state: RootState) => state.windowSize);
  const [user, setUser] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  async function IsUserLoggedIn() {
    const response = await axiosInstance.post('', {
      query: `mutation Mutation { IsUserLoggedIn }`,
    });

    if (!response.data.errors) setUser(true);
  }

  useEffect(() => {
    IsUserLoggedIn(); // Call the login check once on component mount
  }, []);

  async function LogOutHandler(e: React.FormEvent) {
    if (user) {
      e.preventDefault();
      try {
        const res = await axiosInstance.post("", {
          query: `mutation Mutation { Logout }`
        });

        if (res.data.errors) {
          // Handle errors if necessary
        }
        navigate('/auth');
      } catch (e: any) {
      }
    } else {
      navigate("/auth");
    }
  }

  useEffect(() => {
    const section = location.hash.replace("#", "");
    if (section) {
      const sectionElement = document.getElementById(section);
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  const handleMenuClick = (item: string) => {
    setActiveTab(item);    if (item === "Cart") {
      navigate("/cart");
    } else if (item === "Home") {
      navigate("/#Hero");
    } else if (item === "Tickets") {
      navigate("/#proshows");
    } else if (item === "Combos") {
      navigate("/#Combos");
    } else if (item === "Merch") {
      navigate("/merch");}
      else if(item==="Orders"){
        navigate("/myorders")
      }
    
  };
  
  

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <nav  className={`navbar fixed top-0 left-0 w-full z-50 flex items-center justify-between p-4 ${isOpen ? 'backdrop-blur bg-black-custom/50' : ''}`}>
        <a href="/" className={`logo ${device}`}>
          <img src={Logo} alt="Logo" />
        </a>
        {isOpen && (
          <div className="md:hidden flex mr-[25vw] text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-[#840001] via-red-500 to-red-900">
            SAARANG'25
          </div>
        )}
        <div className="md:hidden absolute right-4 top-4">
          <button onClick={toggleMenu} className="text-white bg-transparent focus:outline-none">
            {isOpen ? (
              <RxCross1 className="w-8 h-8" />
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1"
                stroke="currentColor"
                className="w-10 h-10 stroke-white"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16m-7 6h7" />
              </svg>
            )}
          </button>
        </div>

        <div className={`menu hidden md:flex`}>
          {menuItems.map((item) => (
            <div
              key={item}
              className={`menu-item cursor-pointer ${activeItem === item ? 'active' : ''}`}
              onClick={() => handleMenuClick(item)}
              // onClick={handleMenuClick}
            >
              {item}
            </div>
          ))}
        </div>

        <button className={`sign-in menu mt-1 p-2 w-full ${device}`} onClick={LogOutHandler}>
          {user ? "Sign Out" : "Sign In"}
        </button>
      </nav>

      <div className={`fixed top-[4rem]  left-0 w-full md:hidden text-white backdrop-blur bg-black-custom/50 rounded-2xl transition-transform duration-300 transform ${isOpen ? 'translate-y-0 opacity-100' : '-translate-y-[50%] opacity-0'} ease-in-out flex flex-col items-center p-4 ${isOpen?``:`hidden`}`} style={{ zIndex: 100 }}>
        {menuItems.map((item, index) => (
          <div
            key={item}
            className={`menu-item cursor-pointer my-4 w-full text-center transition-opacity duration-300 ease-in-out ${activeItem === item ? 'active h-10 flex justify-center w-full items-center rounded-xl bg-[#840001]' : ''}`}
            style={{ transitionDelay: `${index * 100}ms` }} // Stagger the menu items
            onClick={() => {
              handleMenuClick(item);
              toggleMenu();
            }}
          >
            {item}
          </div>
        ))}
        <button className={`sign-in mt-4 p-2 w-full rounded-2xl bg-[#840001] ${device}`} onClick={LogOutHandler}>
          {user ? "Sign Out" : "Sign In"}
        </button>
      </div>
    </>
  );
};

export default Navbar;
