import React from "react";
import Navbar from "../../components/Navbar/Navbar";

const Merch = () => {
  return (
    <div>
      <Navbar />

      <div className="bg-gradient-to-r from-black-500 to-black-500 w-[100vw] h-[100vh] flex items-center justify-center text-white">
      <div className="text-center space-y-5">
        <h1 className="text-5xl md:text-7xl font-bold animate-pulse">MERCH IS COMING SOON!!</h1>
        <p className="text-lg md:text-2xl italic">Stay tuned for exciting products!</p>
      </div>
    </div>
    </div>
  );
};

export default Merch;
