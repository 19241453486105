import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useAddtoCartMutation,
  useGetCartItemsByUserQuery,
  useGetproshowbyNameQuery,
  useIsUserLoggedInMutation,
  useRemoveFromCartMutation,
} from "../../generated/graphql";
import "./tickets.scss";
import Navbar from "../../components/Navbar/Navbar";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/device/reducers";
import useWindowSize from "../../redux/device/useWindowSize";
import axiosInstance from "../../graphql/axiosConfig";
import ImagePopup from "../../components/ImagePopUps/ImagePopUp";
import { Oatlayout

 } from "../../assets/constants";
import Popup from "../../ui-elements/popup/popup";

const Tickets: React.FC = () => {
  useWindowSize();
  const navigate = useNavigate()
  const { device } = useSelector((state: RootState) => state.windowSize);
  const { night = "" } = useParams<{ night: string }>();
  const [selectedPosition, setSelectedPosition] = useState<string | null>(null);
  const [quantity, setQuantity] = useState<number>(1); // Default quantity set to 1

  // Fetch ProShows filtered by the selected night
  const {
    data: proShowsData,
    loading: proShowsLoading,
    error: proShowsError,
  } = useGetproshowbyNameQuery({
    variables: { name: night }, // Pass the night parameter to the query
  });

  const { data: cartItemsData } = useGetCartItemsByUserQuery();
  const [addToCartMutation,{data:addData,loading:loadingData}] = useAddtoCartMutation();
  const [removeFromCartMutation] = useRemoveFromCartMutation();
  const [isStudent,setIsStudent] = useState(false)
  const [isObsc,setIsObsc] = useState(false)
  const [isUser,setIsUser] = useState(false)
  const [LoginPopUp,setLogInPopUp] = useState(false)
  async function IsUserLoggedIn() {
    const response = await axiosInstance.post('',{
      query:`
      mutation Mutation {
        IsUserLoggedIn
      }
      `,
    })
    
    if(!response.data.errors)
      setIsUser(true)
  }
    IsUserLoggedIn()



const userType = async () => {
  let respon = await axiosInstance.post("", {
    query: `
    query Query {
        IsItStudentMail
    }`,});

  setIsStudent(respon.data.data.IsItStudentMail)

  let respon1 = await axiosInstance.post("",{
      query: `
      query Query {
          IsItObscMail
      }`,});

  setIsObsc(respon1.data.data.IsItObscMail)

  let alum_response = await axiosInstance.post("",{
      query: `
      query Query {
          IsAlumMail
      }`,});
  }

  userType()
  

  // Loading and error handling
  if (proShowsLoading) return <div>Loading...</div>;
  if (proShowsError) return <div>Error loading proshows.</div>;
  if (
    !proShowsData ||
    !proShowsData.getproshowbyName ||
    proShowsData.getproshowbyName.length === 0
  ) {
    return <div>No proshows found for this night.</div>;
  }

  const proshow = proShowsData.getproshowbyName[0]; // Get the first proshow
  const positions = proShowsData.getproshowbyName.map((show) => ({
    id: show.id,
    name: show.Position,
    price: isStudent ? show.Smail_price: show.Price,
  }));
  

  const handlePositionChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedPosition(event.target.value);
  };

  const handleQuantityChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setQuantity(Number(event.target.value));
  };

  const increaseQuantity = () => {
    setQuantity((prev) => prev + 1);
  };

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity((prev) => prev - 1);
    }
  };

  const handleAddToCart = async () => {
    IsUserLoggedIn()
    
    if (isUser) {
      // Proceed only if the user is logged in
      if (selectedPosition) {
        try {
          await addToCartMutation({
            variables: {
              quantity: quantity,
              itemId: selectedPosition,
              itemType: "ProShow",
              name: proshow.Name,
              size: "N/A", // Adjust as needed
            },
          });
          setQuantity(1)
        } catch (error) {
          alert("Failed to add to cart");
        }
      }
    } else {
      setLogInPopUp(true)
      setTimeout(() => {
        navigate("/auth");
      }, 2000);
    }
  };



  const selectedPositionDetails = positions.find(
    (pos) => pos.id === selectedPosition
  );

  return (
    <div className="tickets">
      <Navbar />
      {addData?.AddtoCart?<Popup type="success" message='Added to cart'/>:""}
      {LoginPopUp?<Popup type="normal" message='Please Login in to add items to your cart'/> : ""}

      <div className={`ticket ${device}`}>
        <div>
          {proshow?.Image && (
            <img
              src={device==="mobile"&& proshow.mobileImage?proshow?.mobileImage:proshow.Image}
              alt={proshow.Name}
              className={`ticket-image ${device}`}
            />
          )}
        </div>
        <div className={`ticket-details ${device}`}>
          <div className="">
            <h2 className={`proshow-name ${device}`}>
              {proshow?.Name.toUpperCase()} NIGHT
            </h2>
            <p className={`proshow-description ${device}`}>
              {proshow?.Description}
            </p>
            <div className={`oat-layout-button ${device}`}>
            <ImagePopup imageSrc={Oatlayout} linkText={"OAT Layout"} />
            </div>
            <div>
              <div className={`position mt-5 ${device}`}>
                <div className="w-24">
                  <label htmlFor="ticket-positions" className="ticket-type ">
                    <span className="highlight">Ticket </span>
                  </label>
                </div>
                <div className="text-white">:</div>
                <div className="w-24">
                  <select
                    id="ticket-positions"
                    className="ticket-option-key"
                    onChange={handlePositionChange}
                  >
                    <option
                      value=""
                      disabled
                      selected
                      className="ticket-option-key"
                    >
                      Select
                    </option>
                    {positions.map((position) => (
                      <option
                        key={position.id}
                        value={position.id}
                        className="ticket-option-value"
                      >
                        {position.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          {selectedPositionDetails && (
            <div className={`hidden-part ${device}`}>
              <div className="price-and-quantity">
                <div className={`price ${device}`}>
                  <div className="highlight w-24">Price</div>
                  <div>:</div>
                  <div className="w-24 flex justify-start">
                    ₹{selectedPositionDetails.price}{" "}
                  </div>
                </div>
                <div className={`price ${device}`}>
                  <div className="highlight w-24">Venue</div>
                  <div>:</div>{" "}
                  <div className="w-24 flex justify-start">
                    OAT, IITM{" "}
                  </div>
                </div>

                <div className={`quantity ${device}`}>
                  <span className="highlight w-24">Quantity </span>
                  <div>:</div>
                  <div className="flex justify-start w-24">
                    <div className="flex  justify-start items-center">
                      <button
                        onClick={decreaseQuantity}
                        className="text-white quantity-button"
                      >
                        -
                      </button>
                      <div className="w-8 flex justify-center"> {quantity}</div>
                      <button
                        onClick={increaseQuantity}
                        className="text-white quantity-button"
                      >
                        +
                      </button>
                    </div>     
                  </div>
                </div>
              <div className={`price ${device}`}>
                  <div className="highlight w-24">Total</div>
                  <div>:</div>
                  <div className="w-24 flex justify-start">
                    ₹{(selectedPositionDetails?.price || 0) *
                    (quantity || 0)}{" "}
                  </div>
                </div>
              </div>
              <div className="flex buttons justify-between">
                <button
                  className={`back-to-home-btn ${device}`}
                  onClick={()=>{navigate("/")}}
                >
                  Back to Home
                </button>
                <button
                  className={`add-to-cart-btn ${device}`}
                  onClick={handleAddToCart}
                >
                  Add to Cart
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Tickets;


