import React, { useEffect, useState, useCallback } from "react";
import './cart.scss';
import useRazorpay from "react-razorpay";
import * as constants from "../../assets/constants";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';


import {
  useAddtoCartMutation,
  useCheckoutMutation,
  useClearCartMutation,
  useGetAllCombosQuery,
  useGetCartItemsByUserLazyQuery,
  useGetCartItemsByUserQuery,
  useGetMerchByIdLazyQuery,
  useGetProShowbyIdLazyQuery,
  useGetUserQuery,
  useIsAlumMailLazyQuery,
  useIsItObscMailLazyQuery,
  useIsItStudentMailLazyQuery,
  useIsItStudentMailQuery,
  useIsUserLoggedInMutation,
  useRemoveFromCartMutation,
} from "../../generated/graphql";
import Navbar from "../../components/Navbar/Navbar";
import axiosInstance from "../../graphql/axiosConfig";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/device/reducers";
import useWindowSize from "../../redux/device/useWindowSize";
import { dividerClasses } from "@mui/material";
import Popup from "../../ui-elements/popup/popup";

class TicketObject {
  id: string;
  name: string;
  position: string;
  quantity: number;
  price: number;
  image:string;
  ItemType:string;

  constructor(
    id: string,
    name: string,
    position: string,
    quantity: number,
    price: number,
    image:string,
    ItemType:string
  ) {
    this.id = id;
    this.name = name;
    this.position = position;
    this.quantity = quantity;
    this.price = price;
    this.image = image;
    this.ItemType = ItemType
  }
}

// class Merch_object {
//   name: string;
//   size: string;
//   quantity: number;
//   price: number;

//   constructor(name: string, size: string, quantity: number, price: number) {
//     this.name = name;
//     this.size = size;
//     this.quantity = quantity;
//     this.price = price;
//   }
// }
const Cart: React.FC = () => {
  useWindowSize()
  const { device } = useSelector((state: RootState) => state.windowSize);
  
  const [isUserLoggedInMutation, { data: loggedIn }] =
    useIsUserLoggedInMutation();
  const {
    data: user,
    error: userError,
    loading: userLoading,
  } = useGetUserQuery();
  const [
    checkout,
    { data: checkoutData, error: checkoutError, loading: checkoutLoading },
  ] = useCheckoutMutation();
  const [removeFromCartMutation, { data: removeFromCartData }] =
    useRemoveFromCartMutation();
  const [clearCartMutation] = useClearCartMutation();
  const [getCartItemsQuery,
    {data: cartItemsData,
    loading: cartItemsLoading,
    error: cartItemsError}
  ]= useGetCartItemsByUserLazyQuery();
  const [getProShowbyId, { data: proShowbyIdData }] =
    useGetProShowbyIdLazyQuery();
  const [getMerchbyId, { data: merchbyIdData }] = useGetMerchByIdLazyQuery();
  const {
    data: allCombosData,
    error: allCombosError,
    loading: allCombosLoading,
  } = useGetAllCombosQuery();

  const [IsItStudentMail, { data: IsItStudentMailData }] =
    useIsItStudentMailLazyQuery();
  const [IsItObscMail, { data: IsItObscMailData }] = useIsItObscMailLazyQuery();
  const [IsItAlumMail, { data: IsItAlumMailData }] = useIsAlumMailLazyQuery();
  const [addToCartMutation] = useAddtoCartMutation();

  const [tickets_table, setTicketstable] = useState<TicketObject[]>([]);
  const [merch_table, setMerchtable] = useState<any[]>([]);

  const [Razorpay] = useRazorpay();
  const navigate = useNavigate();
  const [success, setSuccess] = useState("");
  const [error1, setError1] = useState("");
  const [error, setError] = useState("");
  const [coupon, setCoupon] = useState("");
  const [totalPrice, setTotalPrice] = useState(0)
  const [subTotalPrice,setSubTotalPrice] = useState(0)
  const [agreeToTerms,setAgreeToTerms] = useState(false)
  
  

 

  const handlePayment = useCallback(
    (
      orderId: string,
      price: string,
      email: string | undefined,
      name: string | undefined,
      phone: string | undefined
    ) => {
      const options = {
        key: "rzp_live_VgVO9uzPsxViN5",
        amount: price,
        currency: "INR",
        name: "Saarang 2025",
        description: "Payment Transaction",
        image: constants.SAARANG_LOGO,
        notes: {
          type: "Saarang Sales 2025", 
         
      },
        payment_capture: "true",
        order_id: orderId,

        handler: (res: any) => {
          navigate("/myorders")
        },

        prefill: {
          name: name,
          email: email,
          contact: phone,
        },
      
        theme: {
          color: "#FF0000",
        },
        modal: {
          ondismiss: function () {
            setSuccess("");
            setError1("You have closed Razorpay window, payment failed");
            window.location.reload(); // Redirect to home page
          },
        },
      };

      const rzpay = new Razorpay(options);
      rzpay.open();

    },
    [Razorpay]
  );

  async function Checkout() {
    // Uncomment the following lines if you want to check for address
    // if (user?.GetUser.address === null || user?.GetUser.address === "") {
    //     setError("Please fill your address before checkout");
    //     setError2("Redirecting to address page...");
    //     setTimeout(() => {
    //         navigate("/address");
    //     }, 4000);
    //     return;
    // }

    if (totalPrice > 0) {
        try {
            // Send the checkout mutation
            const response = await axiosInstance.post("", {
                query: `
                    mutation Checkout {
                        checkout
                    }
                `,
            });

            // Check for errors in the response
            if (response.data.errors) {
                setError1(response.data.errors[0].message);
                setTimeout(() => {
                  setError1(""); 
              }, 3000);
                return; 
            }

            
            const response_arr = response.data.data.checkout.split(" ");
            if (response_arr) {
                const order_id = response_arr[0];
                const price = response_arr[1];

                // Handle payment with the retrieved order ID and price
                handlePayment(
                    order_id,
                    price,
                    user?.GetUser.email_id,
                    user?.GetUser.username,
                    user?.GetUser.contact_no
                );
            }
            
        } catch (error) {
            // Handle any errors that occurred during the request
            // setError1(response.data.);
            console.error('Checkout error:', error);
        }
    } else {
        setError1("Please Add Items to your cart");
        setTimeout(() => {
          setError1(""); 
      }, 3000);
    }
}











  async function clearCart() {
    const response = await clearCartMutation();

    window.location.reload();
  }

  
  const handleAddToCart = async (item: TicketObject) => {
    const { data: isLoggedInData } = await isUserLoggedInMutation(); // Check if user is logged in
    if (isLoggedInData?.IsUserLoggedIn) {
      // Proceed only if the user is logged in
        try {
          await removeFromCartMutation({
            variables: {
              quantity: -1,
              itemId: item.id,
              itemType: item.ItemType,
              size:item.position,
            },
          });

          setTicketstable((prev) =>
            prev.map((cartItem) =>
              cartItem.id === item.id && cartItem.ItemType === item.ItemType && cartItem.position === item.position
                ? { ...cartItem, quantity: cartItem.quantity + 1 }
                : cartItem
            ));
            let tPrice;
            if (item.ItemType === "Combo") {
              const newQuantity = item.quantity + 1; 
  
              
              const currentComboTotal = item.price * item.quantity * (item.quantity<5?1:0.95);
              tPrice = subTotalPrice - currentComboTotal; 
  
              
              const newDiscountMultiplier = newQuantity < 5 ? 1 : 0.95;
              const newComboTotal = item.price * newQuantity * newDiscountMultiplier; 
              tPrice += newComboTotal; 
          } else {
              
              tPrice = subTotalPrice + item.price; 
          }
            setSubTotalPrice(tPrice)
            setTotalPrice(tPrice*1.02)
        } catch (error) {
          alert("Failed to add to cart");
        }
    } else {
      alert("Please log in to add items to your cart.");
    }
  };

  const handleRemoveFromCart = async (item:TicketObject) => {
    const { data: isLoggedInData } = await isUserLoggedInMutation(); // Check if user is logged in

    if (isLoggedInData?.IsUserLoggedIn) {
      // Proceed only if the user is logged in
      if(item.quantity>1){

        try {
          await removeFromCartMutation({
            variables: {
              quantity: 1,
              itemId: item.id,
              itemType: item.ItemType,
              size:item.position
            },
          });
          setTicketstable((prev) =>
            prev.map((cartItem) =>
              cartItem.id === item.id && cartItem.ItemType === item.ItemType && cartItem.position === item.position
                ? { ...cartItem, quantity: cartItem.quantity - 1 }
                : cartItem
            ));
            let tPrice;
        if (item.ItemType === "Combo") {
            const newQuantity = item.quantity - 1; 

            
            const currentComboTotal = item.price * item.quantity * (item.quantity<5?1:0.95);
            tPrice = subTotalPrice - currentComboTotal; 

            
            const newDiscountMultiplier = newQuantity < 5 ? 1 : 0.95;
            const newComboTotal = item.price * newQuantity * newDiscountMultiplier; 
            tPrice += newComboTotal; 
        } else {
            
            tPrice = subTotalPrice - item.price; 
        }
            setSubTotalPrice(tPrice)
            setTotalPrice(tPrice*1.02)
          
          
        } catch (error) {
          alert("Failed to remove from cart");
        }
      }
    } else {
      if(item)
      alert("Please log in to remove items from your cart.");
    }
  };

  

  
  // getCartItems();

  async function  outerGetCartItems(){
    const getCartItems = async () => {
      const response = await axiosInstance.post('', {
        query:`
        query GetCartItemsByUser {
          GetCartItemsByUser {
            id
            userID
            ItemID
            ItemType
            Quantity
            customization
            Size
          }
        }
        `,
      }
      );
  
      let cart_items = response.data.data.GetCartItemsByUser;
      var ticket_items: any[] = [];
      var merch_items: any[] = [];

      

      
  
      for (var i = 0; i < cart_items.length; i++) {
        
        if (cart_items[i].ItemType === "ProShow") {
          
          const response = await axiosInstance.post('', {
            query:`query GetProShowbyId($id: String!) {
              getProShowbyId(id: $id) {
                Name
                Position
                Price
                Smail_price
                Image
              }
            }
            `,
            variables:{
              "id": cart_items[i].ItemID
            }
          }
          );
          
  
          let ticket_details = response.data.data.getProShowbyId;
          let respon = await axiosInstance.post("", {
            query: `
            query Query {
                IsItStudentMail
              }`,});
  
          if (ticket_details) {
            if (respon.data.data.IsItStudentMail) {
              ticket_items.push(
                new TicketObject(
                  cart_items[i].ItemID,
                  ticket_details.Name,
                  ticket_details.Position,
                  cart_items[i].Quantity,
                  ticket_details.Smail_price ?? 0,
                  ticket_details.Image,
                  cart_items[i].ItemType
                )
              );
            } else
              ticket_items.push(
                new TicketObject(
                  cart_items[i].ItemID,
                  ticket_details.Name,
                  ticket_details.Position,
                  cart_items[i].Quantity,
                  ticket_details.Price,
                  ticket_details.Image,
                  cart_items[i].ItemType
                )
              );
          }
        } else if (cart_items[i].ItemType === "Merch") {
          // merch_items.push(cart_items[i]);
          const response = await axiosInstance.post('', {
            query:`query GetMerchByID($getMerchByIdId: String!) {
              getMerchByID(id: $getMerchByIdId) {
                id
                Name
                Price
                Smail_price
                Image
                Description
                active
              }
            }
            `,
            variables:{
              "getMerchByIdId": cart_items[i].ItemID
            }
          }
          );
  
          let merch_details = response.data.data.getMerchByID;
  
          let respon = await axiosInstance.post("", {
            query: `
            query Query {
                IsItStudentMail
              }`,});
  
          let final_desc = cart_items[i].Size;
  
          if (!(cart_items[i].customization === null)) {
            final_desc = cart_items[i].Size + " - " + cart_items[i].customization;
          }
  
          if (merch_details && final_desc) {
            if (respon.data.data.IsItStudentMail) {
              ticket_items.push(
                new TicketObject(
                  cart_items[i].ItemID,
                  merch_details.Name,
                  final_desc,
                  cart_items[i].Quantity,
                  merch_details.Smail_price ?? 0,
                  merch_details.Image,
                  cart_items[i].ItemType
                )
              );
            } else
              ticket_items.push(
                new TicketObject(
                  cart_items[i].ItemID,
                  merch_details.Name,
                  final_desc,
                  cart_items[i].Quantity,
                  merch_details.Price,
                  merch_details.Image,
                  cart_items[i].ItemType
                )
              );
          }
        } else if (cart_items[i].ItemType === "Combo") {
          const response = await axiosInstance.post("", {
            query: `query GetAllCombos {
            getAllCombos {
              Price
              Description
              id
              Name
              Image
            }
          }
          `,
          });
          let all_combos = response.data.data.getAllCombos;
          let ind = -1;
          for (let t = 0; t < all_combos.length; t++) {
            if (all_combos[t].id === cart_items[i].ItemID) {
              ind = t;
              break;
            }
          }
          // let respon = await axiosInstance.post("", {
          //   query: `
          // query Query {
          //     IsItStudentMail
          //   }`,
          // });

          // let respon1 = await axiosInstance.post("", {
          //   query: `
          // query Query {
          //     IsItObscMail
          //   }`,
          // });

          // let alum_reponse = await axiosInstance.post("", {
          //   query: `
          // query Query {
          //     IsAlumMail
          //   }`,
          // });

          let customization: string;
          if (cart_items[i].customization === null) {
            if (cart_items[i].Size !== "") {
              customization = cart_items[i].Size;
            } else {
              customization = "N/A";
            }
          } else {
            customization =
              cart_items[i].customization + " - " + cart_items[i].Size;
          }

          // if (respon.data.data.IsItStudentMail) {
          //   ticket_items.push(
          //     new TicketObject(
          //       cart_items[i].ItemID,
          //       all_combos[ind].Name,
          //       customization,
          //       cart_items[i].Quantity,
          //       all_combos[ind].Smail_price,
          //       all_combos[ind].Image,
          //       cart_items[i].ItemType
          //     )
          //   );
          // } else if (
          //   respon1.data.data.IsItObscMail &&
          //   all_combos[ind].Price === 0
          // ) {
          //   ticket_items.push(
          //     new TicketObject(
          //       cart_items[i].ItemID,
          //       all_combos[ind].Name,
          //       customization,
          //       cart_items[i].Quantity,
          //       all_combos[ind].Obsc_price,
          //       all_combos[ind].Image,
          //       cart_items[i].ItemType
          //     )
          //   );
          // } else if (
          //   alum_reponse.data.data.IsAlumMail &&
          //   all_combos[ind].Price === 0
          // ) {
          //   ticket_items.push(
          //     new TicketObject(
          //       cart_items[i].ItemID,
          //       all_combos[ind].Name,
          //       customization,
          //       cart_items[i].Quantity,
          //       all_combos[ind].alum_price,
          //       all_combos[ind].Image,
          //       cart_items[i].ItemType
          //     )
          //   );
          // } else {
            ticket_items.push(
              new TicketObject(
                cart_items[i].ItemID,
                all_combos[ind].Name,
                customization,
                cart_items[i].Quantity,
                all_combos[ind].Price,
                all_combos[ind].Image,
                cart_items[i].ItemType
              )
            );
          // }
        }
      
      }
  
      setTicketstable(ticket_items);
         await setMerchtable(merch_items); 

      let tPrice = 0


      for (var i = 0; i < ticket_items.length; i++) {
          const ticketItem = ticket_items[i];
          if (ticketItem.ItemType === "Combo") {
              // Apply discount logic for Combos
              const discountMultiplier = ticketItem.quantity < 5 ? 1 :0.95;
              tPrice += ticketItem.price * ticketItem.quantity * discountMultiplier;
          } else {
              // Regular price calculation for other item types
              tPrice += ticketItem.price * ticketItem.quantity;
          }
      }
      
      setSubTotalPrice(tPrice);
      
      setTotalPrice(tPrice*1.02)
  
  
    }
    await getCartItems();
  }
  
    useEffect(() => {
      outerGetCartItems();
    }, []);

    const handleCheckboxChange = (e:React.ChangeEvent<HTMLInputElement>) => {
      setAgreeToTerms(e.target.checked);
    }


    async function handleDelete(item: TicketObject) {
      try {
        const response = await removeFromCartMutation({
          variables: {
            quantity: item.quantity,
            itemId: item.id,
            itemType: item.ItemType,
            size:item.position,
            name: "N/A",
          },
        });
        if (response?.data?.RemoveFromCart === "Success") {
          // window.location.reload();
          outerGetCartItems()
         
        }
      } catch (error) {
      }
    }

  return (
    <div className=" Cart-Container relative flex-col justify-between w-full h-screen bg-black ">
      <Navbar/>
      {/* {?<Popup type="loading" message={""}/>:""} */}
      {error1!=="" ? <Popup message={error1} type="fails"/>:null}
      
      <div className={`Cart-Section-Outer ${device}`}>
      
      <div className="Cart-Section">
        <div className={`Cart-Elements ${device}`}>
        {tickets_table.length === 0 && (<div className="Cart-Element  width-full text-center text-2xl height-full justify-center">Your Cart is Empty</div>)}      
        {tickets_table.map((item, index) => (
          <div className={`Cart-Element ${device}`}>
            <div className={`Cart-Element-Img ${device}`}>
              <img src={item.image} className="Cart-Element-Image" alt="" />
            </div>
          <div className={`Cart-Element-Details ${device}`}>
            <div className="flex flex-row">
              <div className={`Cart-Element-Data-Value ${device}`}>Item</div>
                <div className="mx-3">:</div>
              <div className="w-28 flex justify-start mr-5">{item.name}</div>
          </div>
    <div className="flex flex-row">
      <div className={`Cart-Element-Data-Value ${device}`}>{item.ItemType =="ProShow"?"Ticket":"Size"}</div>
        <div className="mx-3">:</div>
      <div className="w-28 flex justify-start mr-5">{item.position}</div>
    </div>
    <div className="flex flex-row">
                  <span className={`Cart-Element-Data-Value ${device}`}>Quantity </span>
                  <div className="mx-3">:</div>
                  <div className="flex justify-start w-28">
                    <div className="flex  justify-start items-center">
                      <button
                        onClick={() => { handleRemoveFromCart(item) }}
                        className="text-white Cart-Element-Button"
                      >
                        -
                      </button>
                      <div className="w-8 flex justify-center"> {item.quantity}</div>
                      <button
                        onClick={() => { handleAddToCart(item) }}
                        className="text-white Cart-Element-Button"
                      >
                        +
                      </button>
                    </div>     
                  </div>
                </div>
                <div className="flex flex-row">
      <div className={`Cart-Element-Data-Value ${device}`}>Price</div>
        <div className="mx-3">:</div>
      <div className="w-28 flex justify-start mr-5">₹{item.price}</div>
    </div>
  </div>
  <div className={`Cart-Element-Delete-Button cursor-pointer ${device}`} onClick={() => handleDelete(item)}>
    <DeleteSharpIcon />
  </div>
</div>


))}
        {!(tickets_table.length=== 0) && (<div className="w-full flex mt-4 justify-center"><button className={`Clear-Cart-Button ${device}`} onClick={clearCart}>
          Clear Cart
        </button></div>)}
</div>

      </div>
      {/* <div className="Coupon-div">
        <input
          type="text"
          className="Coupon-input"
          placeholder="Enter Coupon Code"
          value={coupon}
          onChange={(e) => setCoupon(e.target.value)}
        />
      </div> */}
      <div className={`Total-Container ${device}`}>
        {}

        <h2 className={`Total-Heading ${device}`}>TOTAL</h2>
        <div className="">

        <div className="flex flex-row justify-center ">
          <div className="w-28 ml-12">Sub Total</div>
            <div className="mx-3">:</div>
          <div className="w-28 flex justify-start ">₹{subTotalPrice.toFixed(2)}</div>
        </div>

        <div className="flex flex-row justify-center">
          <div className="w-28 ml-12 text-xm">Rayzorpay fee</div>
            <div className="mx-3">:</div>
          <div className="w-28 flex justify-start ">₹{(totalPrice-subTotalPrice).toFixed(2)}</div>
        </div>
        <hr className="border-t-2 border-white-400 my-2 w-9/12 mx-auto" />
        <div className="flex flex-row justify-center">
          <div className="w-28 ml-12 font-bold">Total</div>
            <div className="mx-3 font-bold">:</div>
          <div className="w-28 flex justify-start font-bold ">₹{totalPrice.toFixed(2)}</div>
        </div>
        </div>
        {/* <div className="text-note">
        for merch, delivery charges of 50/- per
        item will be added (for outside IITM deliveries)
        </div> */}
        {/* <div className="text-note">
         Note: 2% Razorpay charges will be added
        </div> */}
        <div className="flex flex-col justify-center my-5 w-full gap-2">
        <div className="flex justify-center">
        <input 
          type="checkbox" 
          checked = {agreeToTerms}
          onChange={handleCheckboxChange} 
          id="termsCheckbox" 
        />
        <label htmlFor="termsCheckbox" style={{ marginLeft: '8px',fontSize: '13px' }}>
          Yes, I agree to the 
          <a href="https://saarang2024.s3.ap-south-1.amazonaws.com/Saarang_25_Terms%26Conditions.pdf" rel="noreferrer" target="_blank" style={{ color: 'yellow', textDecoration: 'underline', marginLeft: '4px' }}>
            terms and conditions.
          </a>
        </label>
      </div>
        <div className="flex justify-center">
        <button disabled={!agreeToTerms} className={`Proceed-to-Pay-Button flex justify-center ${agreeToTerms?"cursor-pointer":""}`} style={{backgroundColor:agreeToTerms?' #8B0000': '#602626'}}onClick={Checkout}>
          Proceed To Pay
        </button>
        </div>
        </div>
    </div>
      </div>
      </div>
      
  );
};

export default Cart;
