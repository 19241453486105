import React from 'react';
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa';
import { MdEmail } from "react-icons/md";
import { motion } from 'framer-motion';
import { MdPhone } from 'react-icons/md';
import './Footer.scss';
import { MdOutlineMail } from "react-icons/md";
import useWindowSize from '../../redux/device/useWindowSize';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/device/reducers';
const Footer = () => {

  const { device } = useSelector((state: RootState) => state.windowSize);
  return (
    <div className='main-f z-99 flex flex-col  bg-[#880101] text-white'>
      <div className={`main flex flex-row h-[60px] justify-between items-center w-full  px-[15px]`}>
        <div className={`${device === "mobile" ? "flex-col" : "flex"} justify-center`}>

          <div className='flex flex-col'>
            <div className='flex flex-row email-icon-padding '>
              <MdEmail className='text-[22px] text-[#0e0101] mt-[3px] mr-[3px] text-white' />
              <a href="mailto:mns@saarang.org" className='aa flex flex-row text-1xl text-[#0e0101] text-center text-white'>
                <h2>mns@saarang.org</h2>
              </a>
            </div>
            <div className='flex flex-row  email-icon-padding'>
              <MdEmail className='text-[22px] text-[#0e0101] mt-[3px] mr-[3px] text-white' />
              <a href="mailto:devops@saarang.org" className='aa flex flex-row text-1xl text-[#0e0101] text-center text-white'>
                <h2>devops@saarang.org</h2>
              </a>
            </div>
          </div>

          <div className="flex flex-col">
            <div className='flex flex-row  pl-[20px]'>
              <MdPhone className='text-[20px] text-[#0e0101] mt-[3px] mr-[3px] text-white' />
              <a href="tel:+919346866569" className='aa flex flex-row text-1xl text-[#0e0101] text-center text-white'>
                <h2>Renusree +91 93468 66569</h2>
              </a>
            </div>
            <div className='flex flex-row  pl-[20px]'>
              <MdPhone className='text-[22px] text-[#0e0101] mt-[3px] mr-[3px] text-white' />
              <a href="tel:+919652542437" className='aa flex flex-row text-1xl text-[#0e0101] text-center text-white'>
                <h2>Sankalp +91 96525 42437</h2>
              </a>
            </div>
          </div>


        </div>

        <div className='flex flex-row text-[#0e0101]'>
          <div className='text-center justify-self-center text-white font-[700] text-sm py-2 text-white'>
            © Designed and Developed by Saarang DevOps
          </div>
        </div>
        <motion.div className='bb flex flex-row space-x-4 mr-[10px]'>
          <motion.div whileHover={{ scale: 1.2, color: '#0e0101' }}>
            <a href='https://www.facebook.com/saarangiitm/'><FaFacebook className='text-[29px] text-[#0e0101] text-white' /> </a>
          </motion.div>
          <motion.div whileHover={{ scale: 1.2, color: '#0e0101' }}>
            <a href='https://www.instagram.com/saarang_iitmadras'> <FaInstagram className='text-[29px] text-[#0e0101] text-white' /></a>
          </motion.div>
          <motion.div whileHover={{ scale: 1.2, color: '#0e0101' }}>
            <a href='https://www.linkedin.com/company/saarang/posts/?feedView=all'> <FaLinkedin className='text-[29px] text-[#0e0101] text-white' /></a>
          </motion.div>
          <motion.div whileHover={{ scale: 1.2, color: '#0e0101' }}>
            <a href='https://x.com/saarang_iitm'> <FaTwitter className='text-[29px] text-[#0e0101] text-white' /></a>
          </motion.div>
        </motion.div>
      </div>

    </div>
  );
};

export default Footer;
