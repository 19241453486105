import React, { useEffect, useState } from "react";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import './proshow.scss';
import { useNavigate } from "react-router-dom";
import { RootState } from "../../redux/device/reducers";
import { useSelector } from "react-redux";
import useWindowSize from "../../redux/device/useWindowSize";

// Define the type for the carousel item
interface CarouselItem {
  src: string;
  mobilesrc:string;
  alt: string;
  page:string;
}

interface CarousalProps {
  data: CarouselItem[];
}

const Carousal: React.FC<CarousalProps> = ({ data }) => {
  useWindowSize()
  const { device } = useSelector((state: RootState) => state.windowSize);

  const [slide, setSlide] = useState(0);

const navigate = useNavigate()
  const nextSlide = () => {
    setSlide(slide === data.length - 1 ? 0 : slide + 1);
  };

  const prevSlide = () => {
    setSlide(slide === 0 ? data.length - 1 : slide - 1);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 3000);

    return () => clearInterval(interval);
  }, [slide]);

  const getSlideClass = (idx: number) => {
    if (idx === slide) return "slide center";
    if (idx === (slide + 1) % data.length) return "slide right";
    if (idx === (slide - 1 + data.length) % data.length) return "slide left";
    return "slide hidden";
  };

  return (
    <div className="container-car" id="proshows">
      <div className="carousel xs:h-[40vh]">
        <h1 className="headline-p">TICKETS</h1>
        <div className="slide-wrapper">
          {data.map((item, idx) => (
            <img
              src={device==="mobile"?item.mobilesrc:item.src}
              alt={item.alt}
              key={idx}
              className={getSlideClass(idx)}
              style={{cursor:'pointer'}}
              onClick={() => navigate(`/proshows/${item.page}`)}
              />
          ))}
        </div>

        <div className="arrow-sec">
          <button
            onClick={prevSlide}
            className="proshow-button left rounded-tl-2xl rounded-tr-none rounded-bl-2xl rounded-br-none mr-[1px]"
          >
            Prev
          </button>
          <button
            onClick={nextSlide}
            className="proshow-button right rounded-tl-none rounded-tr-2xl rounded-bl-none rounded-br-2xl"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Carousal;
