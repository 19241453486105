import React, { useState, useEffect } from "react";
import axiosInstance from "../../graphql/axiosConfig";

interface ProShow {
    Description: string;
    Image: string;
    mobileImage?:string;
    Name: string;
    Position: string;
    Price: number;
    Smail_price: number;
    id: string;
    active: string | null;
    soldTicketsCount?: number;
    available?:number
  }
interface EditProShowModalProps {
  proShow: ProShow | null;
  onClose: () => void;
  onSave: () => void;
}

const EditProShow: React.FC<EditProShowModalProps> = ({ proShow, onClose, onSave }) => {
  const [updatedProShow, setUpdatedProShow] = useState<ProShow | null>(null);

  useEffect(() => {
    if (proShow) {
      setUpdatedProShow(proShow);
    }
  }, [proShow]);

  const handleSave = async () => {
    if (!updatedProShow) return;

    const mutation = `
      mutation editProShow($id: String!, $position: String!, $price: Float!, $name: String!, $smailPrice: Float!, $description: String!, $image: String!, $available: Float!, $mobileImage: String) {
        editProShow(id: $id, position: $position, price: $price, name: $name, smail_price: $smailPrice, description: $description, image: $image, available: $available, mobileImage:$mobileImage) {
          id
        }
      }
    `;

    const variables = {
      id: updatedProShow.id,
      position: updatedProShow.Position,
      price: updatedProShow.Price,
      name: updatedProShow.Name,
      smailPrice: updatedProShow.Smail_price,
      description: updatedProShow.Description,
      image: updatedProShow.Image,
      available: updatedProShow.available,
      mobileImage:updatedProShow.mobileImage,
    };

    try {
      await axiosInstance.post("", {
        query: mutation,
        variables,
      });
      onSave();
      onClose(); // Close the modal
    } catch (error) {
      alert("Error updating ProShow:");
    }
  };

  if (!updatedProShow) return null;

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg p-8">
        <h2 className="text-xl mb-4">Edit ProShow</h2>
        <div className="space-y-4">
        <select
             value={updatedProShow.Name}
             onChange={(e) =>
               setUpdatedProShow({ ...updatedProShow, Name: e.target.value })
             }
             className="border p-2 w-full mb-2"
           >
             <option value="" disabled>
               Select Name
             </option>
             <option value="rock">Rock</option>
             <option value="pop">Pop</option>
             <option value="edm">EDM</option>
             <option value="choreo">Choreo</option>
           </select>
     
           {/* Dropdown for Position */}
           <select
             value={updatedProShow.Position}
             onChange={(e) =>
               setUpdatedProShow({ ...updatedProShow, Position: e.target.value })
             }
             className="border p-2 w-full mb-2"
           >
             <option value="" disabled>
               Select Position
             </option>
             <option value="fanpass">Fanpass</option>
             <option value="bowl">Bowl</option>
             <option value="gallery">Gallery</option>
             <option value="gallery+">Gallery+</option>
           </select>
          <input
            type="number"
            value={updatedProShow.Price}
            onChange={(e) => setUpdatedProShow({ ...updatedProShow, Price: parseFloat(e.target.value) })}
            placeholder="Price"
            className="border p-2 w-full"
          />
          <input
            type="number"
            value={updatedProShow.Smail_price}
            onChange={(e) => setUpdatedProShow({ ...updatedProShow, Smail_price: parseFloat(e.target.value) })}
            placeholder="Smail Price"
            className="border p-2 w-full"
          />
       
          <textarea
            value={updatedProShow.Description}
            onChange={(e) => setUpdatedProShow({ ...updatedProShow, Description: e.target.value })}
            placeholder="Description"
            className="border p-2 w-full"
          />
          <input
            type="text"
            value={updatedProShow.Image}
            onChange={(e) => setUpdatedProShow({ ...updatedProShow, Image: e.target.value })}
            placeholder="Image URL"
            className="border p-2 w-full"
          />
             <input
            type="text"
            value={updatedProShow.mobileImage}
            onChange={(e) => setUpdatedProShow({ ...updatedProShow, mobileImage: e.target.value })}
            placeholder="Mobile Image URL"
            className="border p-2 w-full"
          />
             <input
            type="number"
            value={updatedProShow.available}
            onChange={(e) => setUpdatedProShow({ ...updatedProShow, available: parseFloat(e.target.value) })}
            placeholder="Available"
            className="border p-2 w-full"
          />
        </div>

        <div className="mt-4 flex justify-end space-x-2">
          <button onClick={onClose} className="bg-gray-500 text-white px-4 py-2 rounded">
            Cancel
          </button>
          <button onClick={handleSave} className="bg-blue-500 text-white px-4 py-2 rounded">
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditProShow;
