import { useSelector } from "react-redux";
import { RootState } from "../../redux/device/reducers";
import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import React from "react";
// import PortalContext from "../../contexts/portalContext";
// import AuthContext from "../../contexts/authContext";


import "./auth.scss";
import SlidingImage1 from "../../Images/Sliding-Image-1.jpg";
import SlidingImage2 from "../../Images/Sliding-Image-2.jpg";
import SlidingImage3 from "../../Images/Sliding-Image-3.jpg";
import SlidingImage4 from "../../Images/Sliding-Image-4.jpg";
import SlidingImage5 from "../../Images/Sliding-Image-5.jpg";
import SlidingImage6 from "../../Images/Sliding-Image-6.jpg";
import SlidingImage7 from "../../Images/Sliding-Image-7.jpg";
import useWindowSize from "../../redux/device/useWindowSize";
import ValidateEmail from "../../regexexpressions/emailValidation";
import ValidateMobile from "../../regexexpressions/mobileValidation";
import Popup from "../../ui-elements/popup/popup";
import { useLoginMutation, useRegisterMutation } from "../../generated/graphql";
const AuthPage: React.FC = () => {
  useWindowSize();
  const { device } = useSelector((state: RootState) => state.windowSize);
  // const portalContext = useContext(PortalContext);

  const { portal } = useParams<{ portal: string }>();
  // if (portal) {
  //   portalContext?.changeRecPortal(portal);
  // }

  const [user, setUser] = React.useState({
    email_id: "", // value for 'emailPrimary'
    username: "", // value for 'username'
    password: "", // value for 'password'
    contact_no:""
    
    // value for 'region'
  });
  const [loginUser, setLoginUser] = React.useState({
    email: "",
    password: "",
  });
  // const authContext = React.useContext(AuthContext);

  const Images = [
    SlidingImage1,
    SlidingImage2,
    SlidingImage3,
    SlidingImage4,
    SlidingImage5,
    SlidingImage6,
    SlidingImage7,
  ];
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [error1, setError1] = useState("");
  const [error2, setError2] = useState("");

  const [regError, setRegError] = useState("");
  const [logError, setLogError] = useState("");
  const [activeForm, setActiveForm] = useState("login");
  const [first, setFirst] = useState("");
  const [second, setSecond] = useState("hide");
  const [option, setOption] = useState("Yes");

  const [isRegisterPopupVisible, setIsRegisterPopupVisible] = useState<boolean>(false);
  const [isLoginPopupVisible, setIsLoginPopupVisible] = useState<boolean>(false);



 
  const [
    loginMutation,
    { data: loginData, loading: logonLoadinf, error: loginError },
  ] = useLoginMutation({});

  const [RegisterMutation, { data, loading, error }] =
    useRegisterMutation({
      variables: {
        emailId: user.email_id, // value for 'emailPrimary'
        username: user.username, // value for 'username // value for 'dob'
        password: user.password, // value for 'password'
        contactNo: user.contact_no,
      },
    });

  useEffect(() => {
    if (data) {
      // console.log(data);
      // console.log("Reg user done");
      // window.location.reload();
      // authContext?.signUp(data?.register);
      // console.log("CHECK EMAIL");
      if (data?.Register) {
        setIsRegisterPopupVisible(true);
        setTimeout(() => {
          setIsRegisterPopupVisible(false);
          window.location.reload();
        }, 2000); // Display the popup for 2 seconds
      }
    }
  }, [data]);

  useEffect(() => {
    if (loginData) {
      // console.log(data);
      // console.log("Reg user done");
      // window.location.reload();
      // authContext?.signUp(data?.register);
      // console.log("CHECK EMAIL");
      if(loginUser.email==="mns@saarang.org"){
        window.location.href="/admin"
      }
      else{
      window.location.href = "/";}
    }
  }, [loginData]);

  useEffect(() => {
    //If registration error
    if (error) {
      if (error.message === "Error: email already exists") {
        setRegError(
          "You have already registered in one of the Saarang Portals. Please login to continue"
        );
      }
    } else {
      setRegError("");
    }
  }, [error]);

  const registerSubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // if (!ValidateEmail(user.email_id)) {
    //   setError1("Please Enter a valid email");
    //   return;
    // }
    if (!ValidateMobile(user.contact_no)) {
      setError1("Please enter a valid phone number");
      return;
    }
    if (user.password !== passwordConfirm) {
      setError1("Passwords do not match");
      return;
    } 

    if (
      user.email_id === "" ||
      user.password === "" ||
      user.username === "" ||
      user.contact_no === "" 
    ) {
      setError1("Please fill all the fields");
      return;
    } else {
      try {
        await RegisterMutation();
      } catch (err: any) {
        setError1(err.message);
      }
    }
  };
  const loginSubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (loginUser.email === "" || loginUser.password === "") {
      setError2("Please fill all the fields");
      return;
    }

    // if (!ValidateEmail(loginUser.email)) {
    //   setError2("Please Enter a valid email");
    //   return;
    // }

    try {
      const { data } = await loginMutation({
        variables: {
          email: loginUser.email,
          password: loginUser.password,
        },
      });

      // Handle success, if needed
    } catch (err: any) {

      if (err.message === "Account Not Found") {
        setError2("Account Not Found");
      } else if (err.message === "Invalid Credential") {
        setError2("Invalid Credential");
      } else {
        setError2(err.message);
      }
    }
  };

  const [currentIndex, setCurrentIndex] = useState<number>(
    Math.floor(Math.random() * 7)
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % Images.length);
    }, 3000); // Change image every 3 seconds
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="signup_box">
       {isRegisterPopupVisible && <Popup message="Registration Successful!" type={""} />} 
       {error && <Popup message={error.message.split(":")[1]} type={"error"}/>} 
             {loading && <Popup message="loading.." type={"loading"}/>}
             {data && <Popup message=" Check your mail for verification link" type={"success"}/>}
      <div className={`registration-form-and-image ${activeForm} ${device}`}>
        <div className={`sliding-image ${activeForm} ${device} `}>
          {Images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Slide ${index}`}
              className={`image-slider__image ${
                index === currentIndex ? "active" : ""
              }`}
            />
          ))}
        </div>
        <div
          className={`registration-form  ${
            activeForm === "register" ? "active" : ""
          }`}
        >
          <form onSubmit={registerSubmitHandler}>
            <div className={`registration-box ${device}`}>
              <div className={`heading text-white ${device}`}>REGISTER</div>
              <div className={`text-white sub-heading ${device}`}>
                Create your Saarang Account!!!
              </div>

              <div className={`inputbox-page ${first} fields`}>
                <input
                  type="text"
                  className="form-styling"
                  id="name"
                  placeholder=" Enter name*"
                  value={user.username}
                  onChange={(e) => {
                    setUser({ ...user, username: e.target.value });
                    setError1("");
                    setRegError("");
                  }}
                />

                <input
                  type="text"
                  className="form-styling"
                  id="email"
                  placeholder=" Enter email*"
                  value={user.email_id}
                  onChange={(e) => {
                    setUser({ ...user, email_id: e.target.value });
                    setError1("");
                    setRegError("");
                  }}
                />
                
                <input
                  type="text"
                  className="form-styling"
                  id="phone_number"
                  placeholder=" Phone number*"
                  value={user.contact_no}
                  onChange={(e) => {
                    setUser({ ...user, contact_no: e.target.value });
                    setError1("");
                    setRegError("");
                  }}
                />
                <input
                  type="password"
                  className="form-styling"
                  id="password"
                  placeholder=" Enter password*"
                  value={user.password}
                  onChange={(e) => {
                    setUser({ ...user, password: e.target.value });
                    setError1("");
                    setRegError("");
                  }}
                />
                <input
                  type="password"
                  className="form-styling"
                  id="passwordConfirm"
                  placeholder=" Confirm password*"
                  value={passwordConfirm}
                  onChange={(e) => {
                    setPasswordConfirm(e.target.value);
                    setError1("");
                    setRegError("");
                  }}
                />
                <div className="width100">
                  <div
                    className={`text-white dont-have-account ${device} login-here`}
                    onClick={() => {
                      setActiveForm("login");
                    }}
                  >
                    Already have an account? Login here
                  </div>
                  <button
                    type="submit"
                    className="button-style register-button"
                    onClick={() => registerSubmitHandler}
                  >
                    Register
                  </button>
                </div>
              </div>

              {regError !== "" ? (
                <p className="text-danger redir_log">{regError}</p>
              ) : null}
              {error1 !== "" ? (
                <p className="text-danger redir_log">{error1}</p>
              ) : null}
            </div>
          </form>
        </div>

        <div
          className={`registration-form ${
            activeForm === "login" ? "active" : ""
          }`}
        >
          <form onSubmit={loginSubmitHandler}>
            <div className={`registration-box ${device}`}>
              <h3 className={`login-heading text-white  ${device}`}>LOGIN</h3>
              <div className={`text-white login-subheading ${device}`}>
                Login to your Saarang Sales Account!!!
              </div>
              <div className="login-input">
                <input
                  type="text"
                  className="form-styling"
                  id="email"
                  placeholder=" Enter email*"
                  value={loginUser.email}
                  onChange={(e) => {
                    setLoginUser({ ...loginUser, email: e.target.value });
                    setError2("");
                    setLogError("");
                  }}
                />

                <input
                  type="password"
                  className="form-styling"
                  id="password"
                  placeholder=" Enter password*"
                  value={loginUser.password}
                  onChange={(e) => {
                    setLoginUser({ ...loginUser, password: e.target.value });
                    setError2("");
                    setLogError("");
                  }}
                />
                <div className={`text-white register-and-forgot-password ${device} `}>

                <div
                  onClick={() => {
                    setActiveForm("register");
                  }}
                >
                  New here? Register!
                </div>
                <div
                  className={`text-white forgot-password ${device}`}
                >
                  < Link to="/forgotpassword" style={{ textDecoration: 'none' }}> <span className="highlight">Forgot password</span></Link>
                </div>
                </div>

                <div className="width100">
                  {/* {loading ? (
                    <p className="loader" role="status"></p>
                  ) : ( */}
                    <button type="submit" className="button-style">
                      Login
                    </button>
                  {/* )} */}
                </div>
              </div>

              <div className="" style={{ paddingBottom: "10px" }}></div>
              {/* <div className={` text-white dont-have-account ${device}`}>
                 <a href="/forgotpassword" className="text-white" >
                  Forgot Password
                </a> */}
              {error2 !== "" ? (
                <span className="redir_reg text-danger">{error2}</span>
              ) : null}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default AuthPage;
