import React, { useState, useEffect } from 'react';

interface CountdownProps {
  targetDate: string; // The target date as a string
}

const Timer: React.FC<CountdownProps> = ({ targetDate }) => {
  const [timeLeft, setTimeLeft] = useState<string>("");

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date().getTime();
      const countdownTime = new Date(targetDate).getTime();
      const difference = countdownTime - now;

      if (difference <= 0) {
        return "Time's up!";
      }

      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      // Format the display based on remaining time
      if (days > 0) {
        return `${String(days).padStart(2, '0')}:${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
      } else if (hours > 0) {
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
      } else if (minutes > 0) {
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
      } else {
        return `${String(seconds).padStart(2, '0')} seconds`;
      }
    };

    const updateTimer = () => {
      setTimeLeft(calculateTimeLeft());
    };

    updateTimer(); // Update immediately on mount

    const interval = setInterval(updateTimer, 1000);

    return () => clearInterval(interval); // Cleanup on unmount
  }, [targetDate]);

  return (
    <div className='flex flex-col justify-center w-full align-middle'>
      <h1 className='flex justify-center mt-3 font-medium w-full'>THE EARLY BIRD SALE ENDS IN</h1>
      <div className='flex justify-center  ' style={{ fontSize: "40px",lineHeight:"50px",fontWeight:"bold", color: "#ff6600" }}>{timeLeft}</div>
    </div>
  );
};

export default Timer;


