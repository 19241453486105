import React, { useState } from 'react';
import './Faq.scss';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import Circle from '../../Images/circle.png';

const faqs = [
  {
    question: '001.How can I purchase tickets for Saarang ProShows?',
    answer: 'You can buy tickets directly from our sales portal by selecting the event and following the payment process.',
  },
  {
    question: '002.What payment methods are accepted on the sales portal?',
    answer: 'We accept all major credit/debit cards, UPI, and net banking.',
  },
  {
    question: '003.What should I do if I face issues during payment?',
    answer: 'If you encounter any payment issues, contact our support team at our mail for assistance.'

  },
];














const Faq: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const toggleFAQ = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-container pb-[5vh]">
      <div className="faq-box">
        <div className="faq-h ">
          <h1 className="faqq">FAQs</h1>
          <img className="circle" src={Circle} alt="circle" />
        </div>
        <div className="faq-content">
          {faqs.map((faq, index) => (
            <div key={index} className={`faq-item ${activeIndex === index ? 'active' : ''}`}>
              <div className="faq-question" onClick={() => toggleFAQ(index)}>
                {faq.question}
                <span className="arrow">{activeIndex === index ? <IoIosArrowUp /> : <IoIosArrowDown />}</span>
              </div>
              <div className="faq-answer">{faq.answer}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Faq;
