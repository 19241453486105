type Device = "laptop" | "mobile" | "tab" | "notebook";

const calculateDeviceType = (width: number): Device => {
  if (width > 760) {
    return 'laptop';
  } else if (width > 450) {
    return 'tab';
  } else {
    return 'mobile';
  }
};

export const setWindowSize = (width: number, height: number) => ({
  type: 'SET_WINDOW_SIZE',
  payload: { width, height, device: calculateDeviceType(width) },
});
