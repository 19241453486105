import React, { useState, useEffect } from "react";
import axiosInstance from "../../graphql/axiosConfig";

interface Combo {
  id: string;
  Name: string;
  Description: string;
  Image?: string;
  mobileImage?:string;
  Price: number;
  available:number
}

interface EditComboPopupProps {
  onClose: () => void;
  isVisible:boolean;
  combo: Combo;
  onComboEdit: (updatedCombo: Partial<Combo>) => void;
}

const EditComboPopup: React.FC<EditComboPopupProps> = ({
  onClose,
  combo,
  isVisible,
  onComboEdit,
}) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [mobileImage, setMobileImage] = useState("");
  const [price, setPrice] = useState<number>();
  const [available, setAvailable] = useState<number>();


  useEffect(() => {
    if(combo!==null && combo.available!==null){
    setName(combo.Name||"");
    setDescription(combo.Description||"");
    setImage(combo.Image || "");
    setPrice(combo.Price);}
    setAvailable(combo?.available)
    setMobileImage(combo?.mobileImage||"")
  }, [combo]);

  const handleEditCombo = async () => {
    const mutation = `
    mutation EditCombo($editComboId: String!, $newName: String, $description: String, $price: Float, $image: String, $available: Float,$mobileImage: String) {
  EditCombo(id: $editComboId, new_name: $newName, description: $description, price: $price, image: $image, available: $available,mobileImage:$mobileImage)
}`;

    try {
      await axiosInstance.post("", {
        query: mutation,
        variables: {
          editComboId: combo.id,
          newName: name,
          description,
          price,
          image,
          available,
          mobileImage,
        },
      });
      alert("Combo updated successfully!");
      onComboEdit({ Name: name, Description: description, Image: image, Price: price });
      onClose(); // Close the popup after successful edit
    } catch (error: any) {
      alert(`Error updating combo: ${error.message}`);
    }
  };
  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="bg-white p-6 rounded-lg  w-[60%]">
        <h2 className="text-xl mb-4">Edit Combo</h2>
        <div className="mb-4">
          <label className="block mb-2">Name:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="border p-2 w-full"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2">Description:</label>
          <input
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="border p-2 w-full"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2">Image URL:</label>
          <input
            type="text"
            value={image}
            onChange={(e) => setImage(e.target.value)}
            className="border p-2 w-full"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2">Mobile Image URL:</label>
          <input
            type="text"
            value={mobileImage}
            onChange={(e) => setMobileImage(e.target.value)}
            className="border p-2 w-full"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2">Price:</label>
          <input
            type="number"
            value={price}
            onChange={(e) => setPrice(Number(e.target.value))}
            className="border p-2 w-full"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2">Available:</label>
          <input
            type="number"
            value={available}
            onChange={(e) => setAvailable(Number(e.target.value))}
            className="border p-2 w-full"
          />
        </div>
        <div className="flex justify-end gap-5">
          <button
            onClick={handleEditCombo}
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Save
          </button>
          <button
            onClick={onClose}
            className="bg-gray-400 text-white px-4 py-2 rounded"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditComboPopup;
