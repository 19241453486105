import React from "react";
import axios from "axios";

var timeout = 10000

const nodeEnv = process.env.NODE_ENV || "production";

const api_url = nodeEnv === "production" 
  ? "https://api.sales.saarang.org/graphql" 
  : "http://localhost:8000/graphql";


const axiosInstance = axios.create({
    baseURL: api_url,
    timeout: timeout,
    withCredentials: true,
});


export default axiosInstance ; 