import Navbar from "../../components/Navbar/Navbar";
import data from "../../components/Data/carouselData.json"; // default import for JSON
import './Home.scss'
import Hero from '../../components/Hero/Hero'
import Carousal from '../../components/proshow/proshow'
import Combo from '../../components/Combo/Combo'
import Footer from '../../components/Footer/Footer'
import Faq from '../../components/FAQ/Faq'
import Popup from "../../ui-elements/popup/popup";
import Banner from "../../components/Banner/Banner";

const MainPage: React.FC = () => {
    return (
        <div className="mainpage">
            <div className="wrapper">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <Hero />
            <Carousal data={data.slides} />
            
            <Combo />
           
            <Faq  />
            <Footer />
            
        </div>
    )
}

export default MainPage;
