import React, { CSSProperties, useEffect, useState } from "react";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import './Combo.scss';
import { useGetCombosByTypeQuery, useIsUserLoggedInMutation, } from "../../generated/graphql";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../graphql/axiosConfig";
import useWindowSize from "../../redux/device/useWindowSize";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/device/reducers";
import Banner from "../Banner/Banner";

const Combo = () => {
  const { device } = useSelector((state: RootState) => state.windowSize);
  const [isUser, setIsUser] = useState(false);

  const { data, loading, error } = useGetCombosByTypeQuery({
    variables: {
      types: ["ticket","common"]
    },
  });

  const [zoomOut, setZoomOut] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [flipDirection, setFlipDirection] = useState("forward");
  
  const navigate = useNavigate();

  const handleClick = (combo: any) => {
    navigate(`/combos/${combo.id}`);
  };
  
  async function IsUserLoggedIn() {
    const response = await axiosInstance.post("", {
      query: `
      mutation Mutation {
        IsUserLoggedIn
      }
      `,
    });

    if (!response.data.errors) setIsUser(true);
  }
  IsUserLoggedIn();
  
 

  const combos = data?.getCombosByType ?? [];

  const nextSlide = () => {
    setFlipDirection("forward");
    setCurrentIndex((prevIndex) => (prevIndex === combos.length - 1 ? 0 : prevIndex + 1));
  };

  const prevSlide = () => {
    setFlipDirection("backward");
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? combos.length - 1 : prevIndex - 1));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 3500);

    return () => clearInterval(interval);
  }, [currentIndex]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const carouselStyle: CSSProperties = {
    "--current-index": currentIndex,
  } as CSSProperties;

  if(isUser===false){
    return(
      <div className="container-car-c" id="Combos">
  <h1 className="headline no-user">COMBOS</h1>
  <div className="md:w-[70vw] md:h-[70%] w-[80vw] h-[80vw] text-white font-bold text-xl xs:text-lg font-urbanist py-[20vh] px-6 rounded-lg shadow-lg bg-white bg-opacity-10 flex justify-center items-center">
    Please log in to explore our exclusive combo offers!
  </div>
</div>
    )
  }
  return (
    <div className="container-car-c " id="Combos">
      <h1 className="headline">COMBOS</h1>
      <div className={`carousel-c ${combos.length<2?``:flipDirection}`} style={carouselStyle}>
        <div className="slide-wrapper-c">
          {combos.length > 0 ? (
            combos.map((combo, index) => (
              <div
                key={combo.id}
                className={`${combos.length>1?'slide-c':'slide-c-1'} ${index === currentIndex ? 'active' : ''}`}
                onClick={() => handleClick(combo)}
              >
                <img src={device == "mobile"&& combo.mobileImage ? combo.mobileImage:combo.Image} alt={`Combo Image ${index}`} className="combo-img" />
              </div>
            ))
          ) : (
            <div className="w-[70vw] h-[70%] text-white font-bold text-xl font-urbanist py-4 px-6 rounded-lg shadow-lg bg-white bg-opacity-10 flex justify-center items-center">
No combos available.</div>
          )}
        </div>
      </div>
     
      <div className="arrow-sec-c">
        <button
          onClick={prevSlide}
          className="proshow-button left rounded-tl-2xl rounded-tr-none rounded-bl-2xl rounded-br-none mr-[1px]"
        >
          Prev
        </button>
        <button
          onClick={nextSlide}
          className="proshow-button right rounded-tl-none rounded-tr-2xl rounded-bl-none rounded-br-2xl"
        >
          Next
        </button>
      </div>
      <Banner place="home-page"/>
    </div>
  );
};

export default Combo;
