import { combineReducers } from 'redux';

type Device = 'laptop' | 'mobile' | 'tab' | 'notebook';

type WindowSize = {
  width: number;
  height: number;
  device: Device;
};

export interface RootState {
  windowSize: WindowSize;
}

const initialWindowSize: WindowSize = {
  width: window.innerWidth,
  height: window.innerHeight,
  device: window.innerWidth > 760 ? 'laptop' : window.innerWidth > 450 ? 'tab' : 'mobile',
};

type Action = {
  type: string;
  payload?: any;
};

const windowSizeReducer = (state: WindowSize = initialWindowSize, action: Action): WindowSize => {
  switch (action.type) {
    case 'SET_WINDOW_SIZE':
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  windowSize: windowSizeReducer,
});

export default rootReducer;
