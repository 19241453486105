import React, { createContext, useState, ReactNode, FC } from "react";

interface NavbarContextProps {
  activeItem: string;
  setActiveTab: (tab: string) => void;
}

const NavbarContext = createContext<NavbarContextProps>({
  activeItem: "Home", 
  setActiveTab: () => {},
});

interface NavbarProviderProps {
  children: ReactNode;
}

export const NavbarProvider: FC<NavbarProviderProps> = ({ children }) => {
  const [activeItem, setActiveItem] = useState<string>("Home");

  const setActiveTab = (tab: string) => {
    setActiveItem(tab);
  };

  return (
    <NavbarContext.Provider value={{ activeItem, setActiveTab }}>
      {children}
    </NavbarContext.Provider>
  );
};

export default NavbarContext;
