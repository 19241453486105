import React, { useEffect, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
// import { useForgotPasswordLazyQuery } from "../../../generated/graphql";
import { RootState } from "../../../redux/device/reducers";
import SlidingImage1 from "../../../Images/Sliding-Image-1.jpg";
import SlidingImage2 from "../../../Images/Sliding-Image-2.jpg";
import SlidingImage3 from "../../../Images/Sliding-Image-3.jpg";
import SlidingImage4 from "../../../Images/Sliding-Image-4.jpg";
import SlidingImage5 from "../../../Images/Sliding-Image-5.jpg";
import SlidingImage6 from "../../../Images/Sliding-Image-6.jpg";
import SlidingImage7 from "../../../Images/Sliding-Image-7.jpg";
import "./forgot.scss";
import useWindowSize from "../../../redux/device/useWindowSize";
import Popup from "../../../ui-elements/popup/popup";
import { useSendResetLinkMutation } from "../../../generated/graphql";

const ForgotPassword: React.FC = () => {
  useWindowSize();
  const { device } = useSelector((state: RootState) => state.windowSize);
  const [email, setEmail] = useState<string>("");

  const Images = [
    SlidingImage1,
    SlidingImage2,
    SlidingImage3,
    SlidingImage4,
    SlidingImage5,
    SlidingImage6,
    SlidingImage7,
  ];
  const [currentIndex, setCurrentIndex] = useState<number>(
    Math.floor(Math.random() * 7)
  );
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % Images.length);
    }, 3000); // Change image every 3 seconds
    return () => clearInterval(interval);
  }, []);

  // Lazy load the query
  const [sendResetLink, { data, loading, error }] = useSendResetLinkMutation();
  const submitHandler = async () => {
    try {
      await sendResetLink({ variables: { email } }); // Pass the email variable here
    } catch (e) {
    }
  };
  

  return (
    <div className="signup_box">
             {error && <Popup message={error.message.split(":")[1]} type={"error"}/>} 
             {loading && <Popup message="loading.." type={"loading"}/>}
             {data && <Popup message=" Check your mail for reset link" type={"success"}/>}

      <div className={`registration-form-and-image-forgot ${device}`}>
        <div className={`sliding-image-forgot login ${device}`}>
          {Images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Slide ${index}`}
              className={`image-slider__image-forgot ${
                index === currentIndex ? "active" : ""
              }`}
            />
          ))}
        </div>

        <div className={`registration-form-forgot active ${device}`}>
          <div className={`forgot-box ${device}`}>
            <div className={`heading-forgot text-white ${device} `}>
              Change Password
            </div>
            <div className="width100 ">
              <input
                type="email"
                className={`form-styling-forgot ${device}`}
                id="email"
                placeholder=" Enter Email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
            <div className="width100">
              <button type="button" className={`button-style-forgot ${device}`} onClick={submitHandler} >
                Get reset link
              </button>
            </div>
           
         
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
